import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DownloadService {
  public cdnBaseHref = 'https://cdn.brainio.com';

  constructor(private httpClient: HttpClient) {}

  loadLastest(os: 'Mac' | 'Windows' | 'Linux'): Observable<{ version: string, file: string, releaseNotes: string }> {
    let ymlFile = `${this.cdnBaseHref}/beta-mac.yml`;
    let fileRegex = /  - url: (.*).dmg/;
    let ext = '.dmg';
    if (os === 'Windows') {
      ymlFile = `${this.cdnBaseHref}/beta.yml`;
      fileRegex = /  - url: (.*).exe/;
      ext = '.exe';
    }
    return this.httpClient.get(ymlFile, { responseType: 'text' }).pipe(
      map(d => {
        const versionRegex = /version: (.*)/;
        const version = versionRegex.exec(d)[1];

        const file = fileRegex.exec(d)[1] + ext;

        let releaseNotes;
        if (os === 'Mac') {
          const releaseNotesRegex = /releaseNotes: \|\n([\s\S]*)releaseDate/;
          releaseNotes = releaseNotesRegex.exec(d)[1];
        }

        return { version, file, releaseNotes };
      })
    );
  }

}
