import { Component, Input } from '@angular/core';

@Component({
  selector: '[iconGirl]',
  styles: [],
  template: `
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
  	 viewBox="0 0 188.149 188.149" [attr.height]="size" [attr.height]="size" [attr.width]="size" style="enable-background:new 0 0 188.149 188.149;" xml:space="preserve">
     <defs>
 			<circle id="SVGID_1_" cx="94.074" cy="94.074" r="94.074"/>
 		</defs>
 		<use xlink:href="#SVGID_1_"  style="overflow:visible;fill:#D4DCE6;"/>
 		<clipPath id="SVGID_2_">
 			<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
 		</clipPath>
 		<g style="clip-path:url(#SVGID_2_);">
 			<polygon style="fill:#421800;" points="149.561,97.761 149.561,185.173 38.8,183.288 38.8,95.876 			"/>
 			<path style="fill:#F0D0B4;" d="M135.938,58.277v25.775c0,0.452-0.011,0.905-0.02,1.357c-0.011,0.37-0.021,0.73-0.042,1.1
 				c-0.01,0.329-0.03,0.658-0.061,0.987c-0.01,0.236-0.03,0.473-0.051,0.71c-0.011,0.144-0.02,0.277-0.041,0.421
 				c-0.02,0.257-0.042,0.524-0.083,0.781c-0.041,0.494-0.103,0.977-0.175,1.47c-0.01,0.124-0.03,0.247-0.051,0.37
 				c-0.031,0.247-0.072,0.494-0.113,0.74v0.031c-0.061,0.359-0.123,0.709-0.186,1.069c-0.092,0.494-0.185,0.987-0.287,1.47
 				c-0.011,0.021-0.011,0.041-0.02,0.062c-0.011,0.072-0.021,0.144-0.042,0.216c-0.01,0.021-0.01,0.041-0.02,0.062
 				c-0.051,0.268-0.112,0.524-0.175,0.781c-0.02,0.113-0.041,0.227-0.082,0.339c-0.03,0.185-0.082,0.37-0.123,0.555
 				c-0.031,0.103-0.052,0.216-0.093,0.319c-0.02,0.093-0.051,0.185-0.072,0.268c-0.216,0.843-0.462,1.666-0.72,2.488
 				c-0.123,0.391-0.246,0.771-0.38,1.162c-0.011,0.041-0.031,0.082-0.041,0.123c-0.124,0.35-0.247,0.699-0.37,1.049
 				c-0.041,0.103-0.082,0.206-0.124,0.319c-0.123,0.329-0.246,0.658-0.38,0.987c-0.154,0.401-0.319,0.792-0.483,1.183
 				c-0.144,0.35-0.298,0.699-0.452,1.048c-0.021,0.062-0.052,0.113-0.072,0.175c-0.175,0.37-0.339,0.74-0.514,1.1
 				c-0.175,0.38-0.36,0.761-0.556,1.141c-0.37,0.751-0.77,1.501-1.172,2.231l-0.555,0.987c-1.584,2.724-3.342,5.285-5.254,7.649
 				c-0.258,0.34-0.524,0.669-0.803,0.987c-0.38,0.463-0.771,0.915-1.161,1.357c-0.329,0.37-0.669,0.75-1.019,1.11
 				c-0.01,0.021-0.021,0.031-0.041,0.051c-0.986,1.06-1.994,2.067-3.022,3.013c-0.206,0.206-0.422,0.391-0.638,0.586
 				c-0.35,0.319-0.699,0.628-1.059,0.926c-0.277,0.247-0.565,0.483-0.853,0.72c-0.74,0.606-1.48,1.192-2.23,1.737
 				c-0.309,0.236-0.628,0.463-0.946,0.679c-0.514,0.37-1.038,0.72-1.563,1.059c-0.288,0.195-0.586,0.381-0.885,0.556
 				c-1.049,0.648-2.107,1.244-3.156,1.758c-0.257,0.134-0.514,0.257-0.771,0.381c-0.258,0.123-0.524,0.246-0.803,0.37
 				c-0.339,0.154-0.678,0.298-1.018,0.432c-0.134,0.062-0.268,0.113-0.4,0.154c-0.401,0.164-0.812,0.318-1.214,0.452
 				c-0.504,0.186-1.018,0.34-1.521,0.483c-0.35,0.103-0.709,0.195-1.059,0.277c-0.021,0.011-0.042,0.021-0.072,0.021
 				c-0.35,0.082-0.699,0.165-1.049,0.227c-0.093,0.021-0.185,0.041-0.277,0.051c-0.288,0.052-0.576,0.104-0.864,0.134
 				c-0.411,0.063-0.822,0.113-1.233,0.134c-0.143,0.02-0.277,0.031-0.422,0.031c-0.329,0.02-0.647,0.03-0.966,0.03h-0.041
 				c-0.319,0-0.638-0.01-0.957-0.03c-0.123,0-0.246-0.011-0.369-0.031c-0.288-0.01-0.587-0.041-0.895-0.082
 				c-0.063,0-0.134-0.011-0.195-0.02c-0.381-0.052-0.762-0.113-1.152-0.186c-0.329-0.051-0.657-0.123-0.997-0.205
 				c-1.758-0.401-3.567-1.008-5.397-1.82c-1.563-0.688-3.146-1.521-4.719-2.487c-0.792-0.483-1.584-0.998-2.365-1.543
 				c-0.391-0.277-0.781-0.555-1.172-0.843c-1.224-0.895-2.437-1.871-3.63-2.92c-0.328-0.298-0.657-0.597-0.986-0.904
 				c-0.329-0.299-0.658-0.607-0.977-0.905c-0.761-0.729-1.501-1.48-2.211-2.272c-0.052-0.041-0.093-0.092-0.134-0.133
 				c-0.298-0.319-0.597-0.649-0.894-0.987c-0.822-0.926-1.624-1.882-2.385-2.879c-0.299-0.36-0.576-0.729-0.854-1.101
 				c-1.039-1.377-2.025-2.816-2.951-4.308c-0.246-0.401-0.493-0.812-0.729-1.213c-0.288-0.483-0.565-0.977-0.843-1.47
 				c-1.028-1.851-1.975-3.773-2.797-5.758c-0.123-0.288-0.247-0.576-0.35-0.864c-0.165-0.37-0.309-0.751-0.442-1.12
 				c-0.205-0.546-0.4-1.09-0.586-1.635c-0.185-0.545-0.359-1.1-0.534-1.655c-0.165-0.535-0.319-1.069-0.463-1.614
 				c-0.165-0.576-0.319-1.162-0.452-1.758c-0.011-0.062-0.031-0.124-0.042-0.185c-0.195-0.822-0.369-1.655-0.523-2.498
 				c-0.206-1.11-0.37-2.241-0.494-3.382c-0.051-0.36-0.082-0.72-0.113-1.08c-0.041-0.38-0.071-0.75-0.092-1.131
 				c-0.052-0.689-0.083-1.388-0.104-2.087c-0.01-0.452-0.021-0.905-0.021-1.357V58.277c0-22.341,17.51-40.601,39.562-41.794
 				c0.042-0.011,0.072-0.011,0.113-0.011c0.288-0.01,0.576-0.021,0.864-0.031c0.432-0.01,0.853-0.021,1.285-0.021h0.041
 				c0.441,0,0.884,0.01,1.326,0.021c0.339,0.01,0.679,0.021,1.018,0.042h0.031c2.354,0.133,4.668,0.462,6.898,0.966
 				c2.725,0.617,5.347,1.501,7.834,2.622c3.394,1.521,6.55,3.475,9.388,5.809c2.385,1.954,4.555,4.154,6.446,6.58
 				c5.511,7.022,8.831,15.844,8.903,25.446C135.938,58.03,135.938,58.153,135.938,58.277z"/>
 			<path style="fill:#F0D0B4;" d="M148.615,200.745v0.011H39.54v-0.011c0-6.097,0.997-11.978,2.858-17.457
 				c0.021-0.052,0.031-0.104,0.052-0.154c0.339-0.997,0.709-1.984,1.11-2.961v-0.011c0.391-0.977,0.822-1.933,1.274-2.879
 				c1.8-3.794,4.041-7.341,6.642-10.59c0.658-0.812,1.327-1.604,2.016-2.364c0.339-0.391,0.699-0.771,1.059-1.151
 				c0.36-0.37,0.72-0.74,1.09-1.11c1.841-1.841,3.814-3.526,5.892-5.079c1.038-0.771,2.087-1.491,3.177-2.19
 				c0.144-0.092,0.288-0.185,0.432-0.267c0.401-0.258,0.802-0.504,1.214-0.74c0.555-0.329,1.12-0.648,1.686-0.957
 				c0.021-0.02,0.041-0.02,0.041-0.02c0.442-0.236,0.874-0.493,1.306-0.761c1.285-0.771,2.52-1.624,3.691-2.57
 				c4.966-3.947,8.811-9.253,11.001-15.34c0.638-1.789,1.141-3.649,1.48-5.561c0.35-1.975,0.535-4.01,0.535-6.087l7.947,0.041h0.133
 				l8.04,0.041c0,2.036,0.175,4.03,0.515,5.974c0.329,1.912,0.822,3.763,1.449,5.542c2.159,6.106,5.994,11.433,10.96,15.401
 				c0.74,0.606,1.491,1.161,2.282,1.686c0.083,0.063,0.154,0.113,0.237,0.165c0.37,0.247,0.74,0.493,1.131,0.72
 				c0.421,0.257,0.854,0.514,1.285,0.75c0.071,0.02,0.134,0.052,0.185,0.093c0.905,0.504,1.8,1.028,2.674,1.573
 				c0.071,0.041,0.133,0.082,0.205,0.134c0.35,0.216,0.71,0.441,1.049,0.689c0.854,0.545,1.687,1.142,2.498,1.759
 				c0,0.01,0.011,0.01,0.011,0.01h0.01c1.039,0.771,2.046,1.594,3.033,2.437c0.987,0.843,1.933,1.728,2.848,2.643
 				c5.902,5.87,10.456,13.088,13.171,21.139C147.607,188.768,148.615,194.639,148.615,200.745z"/>
 			<path style="fill:#B34FC5;" d="M148.615,200.745H39.54c0-6.097,0.997-11.978,2.858-17.457c0.021-0.052,0.031-0.104,0.052-0.154
 				c0.339-0.997,0.709-1.984,1.11-2.961v-0.011c0.391-0.977,0.822-1.933,1.274-2.879c1.8-3.794,4.041-7.341,6.642-10.59
 				c0.658-0.812,1.327-1.604,2.016-2.364c0.339-0.391,0.699-0.771,1.059-1.151c0.36-0.37,0.72-0.74,1.09-1.11
 				c1.841-1.841,3.814-3.526,5.892-5.079c1.038-0.771,2.087-1.491,3.177-2.19c0.144-0.092,0.288-0.185,0.432-0.267
 				c0.401-0.258,0.802-0.504,1.214-0.74c0.555-0.34,1.12-0.658,1.686-0.967c0.021-0.021,0.041-0.011,0.041-0.011v-0.01
 				c0.442-0.247,0.874-0.493,1.306-0.751c1.285-0.781,2.52-1.645,3.671-2.591c0.01,0.011,0.01,0.011,0.021,0.021
 				c5.325,5.449,12.738,8.832,20.964,8.853h0.133c8.205-0.011,15.628-3.403,20.964-8.842l0.011-0.011
 				c0.729,0.606,1.49,1.172,2.271,1.696c0.083,0.063,0.154,0.113,0.237,0.165c0.37,0.247,0.75,0.483,1.131,0.72
 				c0.421,0.257,0.854,0.504,1.285,0.74c0.071,0.021,0.123,0.062,0.185,0.093c0.905,0.503,1.8,1.027,2.674,1.583
 				c0.071,0.041,0.133,0.082,0.205,0.134c0.35,0.216,0.71,0.441,1.049,0.689c0.854,0.545,1.687,1.142,2.498,1.759h0.021
 				c1.039,0.781,2.057,1.593,3.033,2.446c0.987,0.843,1.933,1.728,2.848,2.643c5.902,5.87,10.456,13.088,13.171,21.139
 				C147.607,188.768,148.615,194.639,148.615,200.745z"/>
 			<path style="fill:#421800;" d="M149.798,170.583c-0.072,0.031-0.154,0.063-0.237,0.072c-5.407,1.408-26.731-24.288-26.947-51.225
 				c-0.01-2.17,0.103-4.35,0.381-6.539c0.72-5.809,3.517-11.392,5.84-17.016c1.83-4.452,3.382-8.914,3.382-13.52
 				c0-24.675-7.155-16.635-27.111-29.95c-4.822-3.208-8.246-5.84-10.93-7.979c-7.412-5.881-9.294-7.988-19.462-7.988
 				c-12.935,0-18.075,22.464-19.545,32.067c-1.583,10.333-1.604,27.081,4.843,43.027c4.995,12.356,4.996,24.47-4.843,37.928
 				c-3.691,5.049-9.346,22.105-16.368,27.605c-2.189,1.717-4.503,2.314-6.939,1.069c-19.186-9.818-7.64-41.331-1.882-61.061
 				c6.2-21.231,8.071-42.75,12.574-64.187C47.632,28.749,68.297,14.036,76.646,20c2.786-4.997,10.076-6.868,17.529-6.868
 				c5.46,0,11.022,1.018,14.97,2.55c30.484,11.834,30.999,40.087,32.623,51.838c1.337,9.706,6.117,18.774,7.793,28.356v0.031
 				c0.36,2.015,0.576,4.071,0.587,6.148c0.071,11.217-1.183,18.805,7.341,28.15C163.955,137.289,159.01,166.543,149.798,170.583z"/>
 			<g>
 				<path style="fill:#E7ECF2;" d="M94.175,158.335l-0.03,0.052l-0.011,0.02c-2.899,4.503-7.7,6.847-12.543,6.847
 					c-3.938,0-7.896-1.552-10.867-4.739l-5.583-5.983c0.946-0.607,1.912-1.173,2.899-1.707c0.021-0.021,0.041-0.011,0.041-0.011
 					v-0.01c0.442-0.247,0.874-0.493,1.306-0.751c1.285-0.781,2.52-1.645,3.671-2.591c0.01,0.011,0.01,0.011,0.021,0.021
 					c5.325,5.449,12.738,8.832,20.964,8.853H94.175z"/>
 				<path style="fill:#E7ECF2;" d="M123.138,154.613l-5.5,5.901c-2.972,3.188-6.93,4.739-10.878,4.739
 					c-4.832,0-9.644-2.344-12.533-6.847l-0.01-0.02l-0.042-0.052c8.205-0.011,15.628-3.403,20.964-8.842l0.011-0.011
 					c0.729,0.606,1.49,1.172,2.271,1.696c0.083,0.063,0.154,0.113,0.237,0.165c0.37,0.247,0.75,0.483,1.131,0.72
 					c0.421,0.257,0.854,0.504,1.285,0.74c0.071,0.021,0.123,0.062,0.185,0.093c0.905,0.503,1.8,1.027,2.674,1.583
 					C123.004,154.52,123.065,154.561,123.138,154.613z"/>
 			</g>
 		</g>
  </svg>
  `,
})
export class IconGirl {
  @Input() size: number;
}
