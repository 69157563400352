import { Component, Input } from '@angular/core';

@Component({
  selector: '[iconMindmap]',
  styles: [
    ''
  ],
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 10" fill="none" width="19" height="10">
    <rect x="0.4275" y="6.26087" width="7.78136" height="3.31167" rx="1.65583" stroke="#333" stroke-width="0.855"/>
    <rect x="10.791" y="6.26087" width="7.78136" height="3.31167" rx="1.65583" stroke="#333" stroke-width="0.855"/>
    <rect x="5.18188" width="8.63636" height="4.16667" rx="2.08333" fill="#333"/>
    <path d="M5.6137 5.83329L9.50007 1.96425" stroke="#333" stroke-width="0.855" stroke-linecap="square"/>
    <path d="M13.3863 5.83329L9.49993 1.96425" stroke="#333" stroke-width="0.855" stroke-linecap="square"/>
  </svg>
  `,
})
export class IconMindmap {
}
