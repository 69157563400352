export enum InternalPages {
  home = 'home',
  download = 'download',
  pricing = 'pricing',
  pressKit = 'pressKit',
  support = 'support',
  terms = 'terms',
  privacy = 'privacy',
  notFound = 'notFound'
}

export enum ExternalPages {
  signIn = 'signIn',
  signUp = 'signUp',
  blog = 'blog',
  social = 'social'
}

export enum ActionLocations {
  body = 'body',
  menu = 'menu',
  footer = 'footer'
}

export interface ExternalPageParams {
  planName?: string;
  currency?: string;
  socialSite?: 'fb' | 'twitter' | 'instagram' | 'linkedin';
}

/*
  Actions
 */
export interface ActionDownloadedApp {
  type: 'downloadedApp';
  actionLocation: ActionLocations.body;
  fromPage: InternalPages.home | InternalPages.download;
  platform: 'Mac' | 'Windows';
  version: string;
}

export interface ActionClickedPage {
  type: 'clickedPage';
  actionLocation: ActionLocations.menu | ActionLocations.footer;
  toPage: InternalPages;
}

export interface ActionClickedExternalPage extends ExternalPageParams {
  type: 'clickedExternalPage';
  actionLocation: ActionLocations;
  toPage: ExternalPages;
  fromPage?: InternalPages;
}

export interface ActionLoadedPage {
  type: 'loadedPage';
  toPage: InternalPages;
}

export type AnalyticsAction = ActionDownloadedApp | ActionClickedPage | ActionClickedExternalPage | ActionLoadedPage;
