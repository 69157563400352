import { Component, Input } from '@angular/core';

@Component({
  selector: '[iconBoy]',
  styles: [],
  template: `
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
  	 viewBox="0 0 188.149 188.149" [attr.height]="size" [attr.height]="size" [attr.width]="size" style="enable-background:new 0 0 188.149 188.149;" xml:space="preserve">
		<defs>
			<circle id="SVGID_1_" cx="94.074" cy="94.074" r="94.074"/>
		</defs>
		<use xlink:href="#SVGID_1_"  style="overflow:visible;fill:#F3E4B2;"/>
		<clipPath id="SVGID_2_">
			<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
		</clipPath>
		<path style="clip-path:url(#SVGID_2_);fill:#ECC19C;" d="M135.973,52.784V84.05c0,0.452-0.01,0.904-0.021,1.356
			c-0.01,0.37-0.02,0.73-0.041,1.101c-0.01,0.329-0.031,0.658-0.062,0.987c-0.01,0.236-0.031,0.473-0.051,0.709
			c-0.011,0.145-0.021,0.277-0.042,0.422c-0.02,0.257-0.041,0.524-0.082,0.781c-0.041,0.493-0.103,0.977-0.175,1.47
			c-0.01,0.124-0.031,0.247-0.051,0.37c-0.031,0.247-0.072,0.494-0.113,0.74v0.031c-0.061,0.359-0.123,0.709-0.185,1.069
			c-0.093,0.493-0.185,0.987-0.288,1.47c-0.01,0.021-0.01,0.042-0.02,0.063c-0.01,0.071-0.021,0.144-0.041,0.216
			c-0.01,0.021-0.01,0.041-0.021,0.062c-0.051,0.268-0.113,0.524-0.175,0.781c-0.02,0.113-0.041,0.227-0.082,0.34
			c-0.031,0.185-0.083,0.369-0.123,0.555c-0.031,0.103-0.051,0.216-0.093,0.318c-0.021,0.093-0.051,0.186-0.072,0.268
			c-0.216,0.843-0.463,1.666-0.72,2.488c-0.124,0.391-0.247,0.771-0.38,1.162c-0.01,0.041-0.031,0.082-0.041,0.123
			c-0.123,0.35-0.247,0.699-0.37,1.049c-0.041,0.103-0.082,0.205-0.123,0.318c-0.124,0.329-0.247,0.658-0.38,0.987
			c-0.154,0.4-0.319,0.791-0.483,1.183c-0.144,0.35-0.298,0.698-0.452,1.048c-0.02,0.063-0.051,0.113-0.072,0.175
			c-0.175,0.37-0.339,0.74-0.514,1.101c-0.175,0.38-0.36,0.761-0.555,1.142c-0.37,0.75-0.771,1.501-1.172,2.23l-0.555,0.987
			c-1.584,2.725-3.341,5.284-5.254,7.649c-0.257,0.339-0.524,0.668-0.802,0.986c-0.38,0.463-0.771,0.915-1.162,1.357
			c-0.35,0.391-0.699,0.781-1.059,1.162c-0.987,1.059-1.995,2.066-3.023,3.013c-0.206,0.205-0.421,0.391-0.637,0.586
			c-0.35,0.318-0.699,0.627-1.059,0.925c-0.277,0.247-0.565,0.483-0.854,0.72c-0.74,0.606-1.48,1.192-2.231,1.737
			c-0.308,0.237-0.627,0.463-0.946,0.679c-0.514,0.37-1.039,0.72-1.563,1.06c-0.288,0.195-0.586,0.38-0.884,0.555
			c-1.049,0.647-2.108,1.244-3.157,1.758c-0.257,0.134-0.514,0.258-0.771,0.381c-0.257,0.123-0.524,0.247-0.802,0.37
			c-0.339,0.154-0.679,0.298-1.018,0.432c-0.134,0.061-0.267,0.113-0.401,0.154c-0.401,0.165-0.812,0.318-1.213,0.452
			c-0.503,0.186-1.018,0.34-1.521,0.483c-0.35,0.103-0.709,0.195-1.059,0.277c-0.021,0.011-0.041,0.021-0.072,0.021
			c-0.35,0.083-0.699,0.165-1.049,0.227c-0.092,0.021-0.185,0.041-0.277,0.052c-0.288,0.051-0.576,0.103-0.864,0.134
			c-0.411,0.062-0.823,0.113-1.234,0.133c-0.144,0.021-0.277,0.031-0.421,0.031c-0.329,0.02-0.658,0.031-0.977,0.031h-0.031
			c-0.318,0-0.637-0.011-0.956-0.031c-0.123,0-0.247-0.01-0.37-0.031c-0.288-0.01-0.586-0.041-0.895-0.082
			c-0.061,0-0.133-0.01-0.195-0.021c-0.38-0.051-0.761-0.113-1.151-0.185c-0.329-0.052-0.658-0.124-0.998-0.206
			c-1.758-0.401-3.567-1.008-5.397-1.819c-1.563-0.689-3.146-1.522-4.719-2.488c-0.791-0.483-1.583-0.997-2.365-1.542
			c-0.391-0.278-0.781-0.556-1.172-0.844c-1.224-0.895-2.437-1.871-3.629-2.92c-0.329-0.298-0.658-0.596-0.987-0.904
			c-0.329-0.298-0.658-0.607-0.977-0.905c-0.802-0.771-1.583-1.572-2.344-2.405c-0.298-0.319-0.596-0.647-0.895-0.987
			c-0.822-0.925-1.624-1.881-2.385-2.879c-0.298-0.359-0.576-0.729-0.853-1.1c-1.039-1.378-2.025-2.817-2.951-4.309
			c-0.247-0.4-0.494-0.811-0.73-1.213c-0.288-0.483-0.565-0.977-0.843-1.47c-1.028-1.851-1.974-3.773-2.796-5.758
			c-0.124-0.288-0.247-0.576-0.35-0.863c-0.164-0.37-0.308-0.751-0.442-1.121c-0.206-0.545-0.401-1.09-0.586-1.635
			c-0.185-0.545-0.36-1.101-0.535-1.655c-0.164-0.535-0.318-1.069-0.462-1.614c-0.165-0.576-0.319-1.162-0.453-1.758
			c-0.01-0.062-0.031-0.124-0.041-0.186c-0.195-0.822-0.37-1.655-0.524-2.498c-0.206-1.11-0.37-2.241-0.493-3.383
			c-0.052-0.359-0.083-0.72-0.113-1.079c-0.041-0.381-0.072-0.751-0.093-1.131c-0.051-0.689-0.082-1.389-0.103-2.088
			c-0.01-0.452-0.021-0.904-0.021-1.356V52.784c0-23.103,18.722-41.835,41.824-41.855h0.042c3.187,0,6.292,0.359,9.273,1.028
			c2.725,0.616,5.347,1.501,7.834,2.621c3.393,1.521,6.549,3.476,9.387,5.81c2.386,1.953,4.555,4.153,6.447,6.58
			c5.511,7.022,8.831,15.844,8.903,25.446C135.973,52.537,135.973,52.66,135.973,52.784z"/>
		<path style="clip-path:url(#SVGID_2_);fill:#ECC19C;" d="M148.65,200.743v0.01H39.575v-0.01c0-6.159,1.018-12.091,2.91-17.612
			c0.339-0.997,0.709-1.984,1.111-2.961v-0.011c0.391-0.977,0.822-1.933,1.274-2.878c1.799-3.794,4.041-7.342,6.642-10.59
			c0.658-0.813,1.326-1.605,2.015-2.365c0.339-0.391,0.699-0.77,1.059-1.151c0.359-0.37,0.72-0.74,1.089-1.11
			c1.841-1.841,3.814-3.526,5.892-5.079c1.038-0.771,2.087-1.491,3.177-2.189c1.09-0.689,2.2-1.348,3.331-1.964
			c0.021-0.02,0.041-0.02,0.041-0.02c0.442-0.237,0.874-0.494,1.306-0.761c1.285-0.771,2.519-1.625,3.691-2.57
			c4.966-3.948,8.811-9.254,11.001-15.34c1.306-3.64,2.015-7.557,2.015-11.649l7.947,0.041l8.174,0.042
			c0,4.04,0.689,7.916,1.964,11.515c2.159,6.107,5.994,11.433,10.96,15.401c0.74,0.607,1.491,1.162,2.282,1.687
			c0.083,0.061,0.154,0.112,0.237,0.164c0.37,0.247,0.74,0.493,1.131,0.72c0.421,0.257,0.853,0.514,1.285,0.751
			c0.072,0.02,0.134,0.051,0.185,0.092c0.905,0.504,1.799,1.028,2.673,1.573c0.422,0.257,0.843,0.524,1.254,0.822
			c0.854,0.545,1.686,1.142,2.499,1.759c0,0.01,0.01,0.01,0.01,0.01h0.01c1.039,0.771,2.046,1.594,3.033,2.437
			c0.987,0.844,1.933,1.727,2.848,2.643C142.532,172.017,148.65,185.651,148.65,200.743z"/>
		<path style="clip-path:url(#SVGID_2_);fill:#EB6D4A;" d="M148.646,200.739H39.572c0-6.159,1.018-12.092,2.91-17.612
			c0.339-0.997,0.71-1.984,1.11-2.961v-0.011c0.391-0.977,0.823-1.933,1.275-2.879c1.799-3.793,4.04-7.341,6.642-10.59
			c0.658-0.812,1.327-1.604,2.015-2.364c0.339-0.391,0.699-0.771,1.059-1.151c0.36-0.37,0.72-0.74,1.09-1.11
			c1.84-1.841,3.814-3.526,5.891-5.079c1.039-0.771,2.087-1.491,3.177-2.19c0.565-0.359,1.131-0.709,1.706-1.048
			c0.535-0.319,1.08-0.628,1.625-0.926c0.021-0.021,0.041-0.01,0.041-0.01v-0.011c0.442-0.247,0.874-0.493,1.306-0.751
			c1.285-0.781,2.519-1.645,3.67-2.591c0.01,0.011,0.01,0.011,0.021,0.021c5.326,5.449,12.739,8.832,20.964,8.853h0.083
			c8.225,0,15.668-3.393,21.015-8.842l0.01-0.011c0.73,0.607,1.491,1.173,2.272,1.697c0.082,0.061,0.154,0.112,0.236,0.164
			c0.37,0.247,0.751,0.483,1.131,0.72c0.421,0.257,0.854,0.504,1.285,0.74c0.072,0.021,0.124,0.062,0.185,0.093
			c0.483,0.268,0.977,0.545,1.45,0.832c0.412,0.247,0.823,0.494,1.224,0.751c0.421,0.257,0.843,0.524,1.254,0.822
			c0.854,0.545,1.686,1.142,2.498,1.759h0.021c1.038,0.781,2.056,1.594,3.033,2.446c0.987,0.844,1.933,1.728,2.848,2.643
			C142.529,172.012,148.646,185.645,148.646,200.739z"/>
		<path style="clip-path:url(#SVGID_2_);fill:#543E36;" d="M135.973,84.05c0,0.452-0.01,0.904-0.021,1.356
			c-0.01,0.37-0.02,0.73-0.041,1.101c-0.01,0.329-0.031,0.658-0.062,0.987c-0.01,0.236-0.031,0.473-0.051,0.709
			c-0.011,0.145-0.021,0.277-0.042,0.422c-0.02,0.257-0.041,0.524-0.082,0.781c-0.052,0.493-0.103,0.986-0.175,1.47
			c-0.01,0.124-0.031,0.247-0.051,0.37c-0.031,0.247-0.072,0.494-0.113,0.74c-0.051,0.37-0.123,0.74-0.185,1.101
			c-0.082,0.432-0.165,0.853-0.257,1.274c-0.011,0.093-0.031,0.175-0.052,0.258c-0.01,0.071-0.031,0.144-0.041,0.216
			c-0.01,0.021-0.01,0.041-0.021,0.062c0,0.051-0.01,0.093-0.02,0.134c-0.051,0.216-0.093,0.432-0.154,0.647
			c-0.02,0.113-0.041,0.227-0.082,0.34c-0.042,0.205-0.093,0.4-0.154,0.596c-0.01,0.093-0.031,0.186-0.062,0.277
			c-0.021,0.093-0.051,0.186-0.072,0.268c-0.216,0.843-0.463,1.666-0.72,2.488c-0.124,0.391-0.247,0.771-0.38,1.162
			c-0.01,0.041-0.031,0.082-0.041,0.123c-0.123,0.35-0.247,0.699-0.37,1.049c-0.041,0.103-0.082,0.205-0.123,0.318
			c-0.124,0.329-0.247,0.658-0.38,0.987c-0.154,0.4-0.319,0.791-0.483,1.183c-0.144,0.35-0.298,0.698-0.452,1.048
			c-0.02,0.063-0.051,0.113-0.072,0.175c-0.175,0.37-0.339,0.74-0.514,1.101c-0.175,0.38-0.36,0.761-0.555,1.142
			c-0.37,0.75-0.771,1.501-1.172,2.23l-0.555,0.987c-1.584,2.725-3.341,5.284-5.254,7.649c-0.257,0.339-0.524,0.668-0.802,0.986
			c-0.38,0.463-0.771,0.915-1.162,1.357c-0.35,0.391-0.699,0.781-1.059,1.162c-0.987,1.059-1.995,2.066-3.023,3.013
			c-0.206,0.205-0.421,0.391-0.637,0.586c-0.35,0.318-0.699,0.627-1.059,0.925c-0.277,0.247-0.565,0.483-0.854,0.72
			c-0.74,0.606-1.48,1.192-2.231,1.737c-0.308,0.237-0.627,0.463-0.946,0.679c-0.514,0.37-1.039,0.72-1.563,1.06
			c-0.288,0.195-0.586,0.38-0.884,0.555c-1.049,0.647-2.108,1.244-3.157,1.758c-0.257,0.134-0.514,0.258-0.771,0.381
			c-0.257,0.123-0.524,0.247-0.802,0.37c-0.339,0.154-0.679,0.298-1.018,0.432c-0.134,0.061-0.267,0.113-0.401,0.154
			c-0.401,0.165-0.812,0.318-1.213,0.452c-0.503,0.186-1.018,0.34-1.521,0.483c-0.35,0.103-0.709,0.195-1.059,0.277
			c-0.021,0.011-0.041,0.021-0.072,0.021c-0.35,0.083-0.699,0.165-1.049,0.227c-0.092,0.021-0.185,0.041-0.277,0.052
			c-0.288,0.051-0.576,0.103-0.864,0.134c-0.411,0.062-0.823,0.113-1.234,0.133c-0.144,0.021-0.277,0.031-0.421,0.031
			c-0.329,0.02-0.658,0.031-0.977,0.031h-0.031c-0.318,0-0.637-0.011-0.956-0.031c-0.123,0-0.247-0.01-0.37-0.031
			c-0.288-0.01-0.586-0.041-0.895-0.082c-0.061,0-0.133-0.01-0.195-0.021c-0.38-0.051-0.761-0.113-1.151-0.185
			c-0.329-0.052-0.658-0.124-0.998-0.206c-1.758-0.401-3.567-1.008-5.397-1.819c-1.563-0.689-3.146-1.522-4.719-2.488
			c-0.791-0.473-1.573-0.987-2.365-1.542c-0.391-0.278-0.781-0.556-1.172-0.844c-1.224-0.895-2.437-1.871-3.629-2.92
			c-0.329-0.298-0.658-0.596-0.987-0.904c-0.329-0.298-0.658-0.607-0.977-0.905c-0.802-0.771-1.583-1.572-2.344-2.405
			c-0.298-0.319-0.596-0.647-0.895-0.987c-0.822-0.925-1.624-1.881-2.385-2.879c-0.298-0.359-0.576-0.729-0.853-1.1
			c-1.039-1.378-2.025-2.817-2.951-4.309c-0.247-0.4-0.494-0.811-0.73-1.213c-0.288-0.483-0.565-0.977-0.843-1.47
			c-1.028-1.851-1.974-3.773-2.796-5.758c-0.124-0.288-0.247-0.576-0.35-0.863c-0.164-0.37-0.308-0.751-0.442-1.121
			c-0.206-0.545-0.401-1.09-0.586-1.635c-0.185-0.545-0.36-1.101-0.535-1.655c-0.164-0.535-0.318-1.069-0.462-1.614
			c-0.165-0.576-0.319-1.162-0.453-1.758c-0.01-0.062-0.031-0.124-0.041-0.186c-0.195-0.822-0.37-1.655-0.524-2.498
			c-0.206-1.11-0.37-2.241-0.493-3.383c-0.052-0.359-0.083-0.72-0.113-1.079c-0.041-0.381-0.072-0.751-0.093-1.131
			c-0.051-0.689-0.082-1.389-0.103-2.088c-0.01-0.452-0.021-0.904-0.021-1.356l6.683,13.14l11.484,5.007L84,103.42h0.01
			l10.065,0.915h0.062l9.417,0.853c0,0,0-0.01,0.01,0l21.005,1.893L135.973,84.05z"/>
		<g style="clip-path:url(#SVGID_2_);">
			<path style="fill:#543E36;" d="M94.159,90.651l-0.01,10.127v0.01l-0.01,3.548v2.786l-0.01,9.253v0.011l-0.01-0.011h-0.01
				l-0.031-0.021L53.64,95.771c-0.01-0.062-0.031-0.124-0.041-0.186c-0.195-0.822-0.37-1.655-0.524-2.498
				c-0.206-1.11-0.37-2.241-0.493-3.383c-0.052-0.359-0.083-0.72-0.113-1.079c-0.041-0.381-0.072-0.751-0.093-1.131
				c-0.051-0.689-0.082-1.389-0.103-2.088c-0.01-0.452-0.021-0.904-0.021-1.356V71.764c0,0.175,0.01,0.35,0.01,0.524
				c0.082,2.971,0.555,6.24,1.47,9.582c0.71,2.57,1.593,4.945,2.622,7.063v0.01c2.694,5.604,6.303,9.407,9.623,10.024
				c0.206,0.031,0.421,0.052,0.627,0.072c1.285,0.144,2.632,0.226,4.02,0.226c5.244,0,9.901-4.215,12.831-5.922
				c0.021-0.01,0.041-0.021,0.062-0.03c0.154-0.093,0.308-0.186,0.442-0.278c0.041-0.03,0.082-0.051,0.124-0.071
				c2.467-1.409,6.025-2.304,9.993-2.313H94.159z"/>
			<path style="fill:#543E36;" d="M135.981,71.759l-0.01,12.286c0,3.084-0.288,6.107-0.822,9.037
				c-0.216,1.172-0.463,2.334-0.751,3.485l-9.49,13.705l-30.783,6.097h-0.01l-0.01,0.011v-0.011l-0.031-25.728h0.083
				c3.968,0.011,7.536,0.905,10.003,2.313c0.042,0.021,0.072,0.041,0.113,0.072c0.144,0.092,0.298,0.185,0.453,0.277
				c0.01,0.01,0.031,0.021,0.051,0.031c2.94,1.706,7.587,5.925,12.831,5.925c1.388,0,2.735-0.082,4.02-0.226
				c0.216-0.021,0.421-0.041,0.637-0.072c3.311-0.617,6.93-4.421,9.613-10.024v-0.01c1.028-2.118,1.912-4.493,2.622-7.063
				c0.925-3.394,1.409-6.704,1.47-9.716C135.981,72.016,135.981,71.892,135.981,71.759z"/>
		</g>
		<ellipse style="clip-path:url(#SVGID_2_);fill:#ECC19C;" cx="94.108" cy="103.95" rx="10.255" ry="3.171"/>
		<path style="clip-path:url(#SVGID_2_);fill:#543E36;" d="M94.116,10.929h-0.04c-23.102,0.021-41.824,18.753-41.824,41.855V71.24
			c0,0,1.737-11.012,6.416-16.358c5.079-5.809,3.794-14.641,15.186-14.641c7.351,0,7.762,2.714,20.213,2.714h0.01h0.04h0.01
			c12.451,0,12.862-2.714,20.213-2.714c11.392,0,10.106,8.831,15.186,14.641c4.678,5.346,6.415,16.358,6.415,16.358V52.784
			C135.94,29.682,117.218,10.949,94.116,10.929z"/>
		<g style="clip-path:url(#SVGID_2_);">
			<path style="fill:#C34628;" d="M121.74,153.721l-1.131,1.152c-7.053,7.186-16.471,11.145-26.516,11.145
				c-10.147-0.021-19.545-3.989-26.567-11.165l-1.08-1.11c0.535-0.319,1.08-0.628,1.625-0.926c0.021-0.021,0.041-0.01,0.041-0.01
				v-0.011c0.442-0.247,0.874-0.493,1.306-0.751c1.285-0.781,2.519-1.645,3.67-2.591c0.01,0.011,0.01,0.011,0.021,0.021
				c5.326,5.449,12.739,8.832,20.964,8.853h0.083c8.225,0,15.668-3.393,21.015-8.842l0.01-0.011c0.73,0.607,1.491,1.173,2.272,1.697
				c0.082,0.061,0.154,0.112,0.236,0.164c0.37,0.247,0.751,0.483,1.131,0.72c0.421,0.257,0.854,0.504,1.285,0.74
				c0.072,0.021,0.124,0.062,0.185,0.093C120.774,153.157,121.267,153.434,121.74,153.721z"/>
		</g>
  </svg>
  `,
})
export class IconBoy {
  @Input() size: number;
}
