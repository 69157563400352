import { AnalyticsService } from '../services/analytics';
import { InternalPages } from '../model';

export class TrackPage {

  constructor(
    protected analyticsService: AnalyticsService,
    protected toPage: InternalPages
  ) {
    this.analyticsService.trackPageLoaded({ toPage });
  }

}
