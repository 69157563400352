import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DownloadService } from '../../services/download';
import { AnalyticsService } from '../../services/analytics';
import { ActionDownloadedApp, ActionLocations, InternalPages } from '../../model';

@Component({
  selector: 'app-download',
  templateUrl: './download.html',
  styleUrls: ['./download.scss']
})
export class DownloadComponent {
  public file: string;
  public version: string;
  public releaseNotes: string;
  public loaded = false;
  public downloadUrl: string;

  constructor(
    private downloadService: DownloadService,
    public deviceDetector: DeviceDetectorService,
    public analyticsService: AnalyticsService
  ) {
    this.downloadService.loadLastest(deviceDetector.os as any).subscribe(({ version, file, releaseNotes }) => {
      this.version = version;
      this.file = file;
      this.releaseNotes = releaseNotes;
      this.downloadUrl = `${this.downloadService.cdnBaseHref}/${this.file}`;
      this.loaded = true;
    });
  }

  download(platform: 'Mac' | 'Windows') {
    const action: ActionDownloadedApp = {
      type: 'downloadedApp',
      actionLocation: ActionLocations.body,
      fromPage: InternalPages.home,
      platform,
      version: this.version
    };
    this.analyticsService.track(action);
  }

}
