import { Component } from '@angular/core';
import { TrackPage } from '../trackPage';
import { AnalyticsService } from '../../services/analytics';
import { InternalPages } from '../../model';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.html',
  styleUrls: ['./privacy.scss']
})
export class PrivacyPage extends TrackPage {

  constructor(
    protected analyticsService: AnalyticsService
  ) {
    super(analyticsService, InternalPages.privacy);
  }

}
