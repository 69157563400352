import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.html',
  styleUrls: ['./blog.scss']
})
export class BlogComponent {
  public posts$: Observable<any>;

  constructor(private http: HttpClient) {
    this.posts$ = this.http.get('https://blog.brainio.com/wp-json/wp/v2/posts?per_page=4&_embed').pipe(
      map((posts: any[]) => {
        return posts.map(post => {
          const title = post.title.rendered;
          const category = post._embedded['wp:term'][0][0].name;
          const link = post.link;
          const featuredImage = post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url;
          return { title, category, link, featuredImage };
        });
      })
    );
  }

}
