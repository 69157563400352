import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-logo',
  styleUrls: ['./logo.scss'],
  template: `
    <a [attr.href]="href">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 65.61 58.72"
        [attr.height]="size" [attr.height]="size" [attr.width]="(65.61 / 58.72) * size">
        <defs>
          <linearGradient [attr.id]="gradientId" x1="71.14" y1="28.43" x2="0.09" y2="29.84" gradientUnits="userSpaceOnUse">
            <stop offset="0"/>
            <stop offset="1"/>
          </linearGradient>
          <linearGradient [attr.id]="gradientId + '-2'" x1="71.15" y1="28.77" x2="0.09" y2="30.18" [attr.xlink:href]="'#' + gradientId"/>
        </defs>
        <g>
          <g>
            <path class="left" d="M28.28.55a5.45,5.45,0,0,0-5.72.6L2.07,17.35a4.58,4.58,0,0,0,0,7.29l5.66,4.48L1.81,33.81a4.58,4.58,0,0,0,0,
              7.29L22.55,57.51a5.37,5.37,0,0,0,3.33,1.14,5.49,5.49,0,0,0,2.39-.55A4.67,4.67,0,0,0,31,53.87V45.95a4.65,4.65,0,0,0-1.81-3.65l-6.9-5.46,
              5-4a4.58,4.58,0,0,0,0-7.29L22.2,21.5l7-5.56A4.65,4.65,0,0,0,31,12.29V4.79A4.67,4.67,0,0,0,28.28.55ZM3.93,22.29a1.58,1.58,0,0,1,0-2.59l5.58-4.41,
              7.85,6.21-7.21,5.71ZM3,37.45a1.65,1.65,0,0,1,.67-1.29L10.15,31l7.34,5.81L9.38,43.26,3.67,38.75A1.65,1.65,0,0,1,3,37.45Zm24.37,
              7.2A1.67,1.67,0,0,1,28,45.95v7.92A1.76,1.76,0,0,1,27,55.41a2.44,2.44,0,0,1-2.56-.25l-12.62-10,8.11-6.42ZM25.49,27.93a1.58,1.58,0,0,1,0,
              2.59l-5.58,4.41-7.34-5.81,7.21-5.71ZM28,12.29a1.67,1.67,0,0,1-.67,1.29l-7.59,6-7.85-6.21L24.41,3.5A2.3,2.3,0,0,1,25.86,3,2.59,2.59,0,0,
              1,27,3.25,1.76,1.76,0,0,1,28,4.79Z"/>
            <path class="right" d="M63.8,33.87l-5.92-4.69,5.66-4.48a4.58,4.58,0,0,0,0-7.29L43.06,1.21a5.44,5.44,0,0,0-5.72-.6,4.67,4.67,0,0,0-2.76,
              4.24v7.5A4.65,4.65,0,0,0,36.39,16l7,5.56-5.15,4.08a4.58,4.58,0,0,0,0,7.29l5,4-6.9,5.46A4.65,4.65,0,0,0,34.58,46v7.92a4.68,4.68,0,0,0,
              2.76,4.24,5.5,5.5,0,0,0,2.39.55,5.37,5.37,0,0,0,3.33-1.14L63.8,41.16a4.58,4.58,0,0,0,0-7.29ZM62.35,21.06a1.65,1.65,0,0,1-.67,1.29l-6.22,
              4.92-7.21-5.71,7.85-6.21,5.58,4.41A1.65,1.65,0,0,1,62.35,21.06Zm-24.1-7.41a1.67,1.67,0,0,1-.67-1.29V4.85a1.76,1.76,0,0,1,1.07-1.54,2.58,
              2.58,0,0,1,1.11-.25,2.3,2.3,0,0,1,1.45.5l12.49,9.88-7.85,6.21Zm1.88,16.93a1.58,1.58,0,0,1,0-2.59l5.71-4.52L53,29.18,45.7,35ZM41.2,
              55.22a2.44,2.44,0,0,1-2.56.25,1.76,1.76,0,0,1-1.07-1.54V46a1.67,1.67,0,0,1,.67-1.29l7.46-5.9,8.11,6.42ZM61.94,38.81l-5.71,4.52L48.12,
              36.9l7.34-5.81,6.48,5.13a1.58,1.58,0,0,1,0,2.59Z"/>
          </g>
        </g>
      </svg>
      <h1 *ngIf="text">{{ text }}</h1>
    </a>
  `,
})
export class LogoComponent {
  @Input() size: number;
  @Input() href: string;
  @Input() text: string;
  @Input() gradientId = 'linear-gradient';
}
