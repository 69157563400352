import { Component } from '@angular/core';
import { TrackPage } from '../trackPage';
import { AnalyticsService } from '../../services/analytics';
import { InternalPages } from '../../model';

@Component({
  selector: 'app-main',
  templateUrl: './main.html',
  styleUrls: ['./main.scss']
})
export class MainPage extends TrackPage {

  constructor(
    protected analyticsService: AnalyticsService
  ) {
    super(analyticsService, InternalPages.home);
  }

}
