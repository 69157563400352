import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AnalyticsService } from '../../services/analytics';
import { InternalPages } from '../../model';
import { TrackPage } from '../trackPage';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.html',
  styleUrls: ['./pricing.scss']
})
export class PricingPage extends TrackPage {
  public isLoading = true;
  public plans: any[];

  constructor(
    private httpClient: HttpClient,
    public analyticsService: AnalyticsService
  ) {
    super(analyticsService, InternalPages.pricing);
    this.httpClient.get('https://api.brainio.com/v1/billing/plans').subscribe((d: any) => {
      const currencyCode = d.isInEu ? 'EUR' : 'USD';
      this.plans = d.plans.filter(p => p.currencyCode === currencyCode);
      this.plans.push({
        id: 'enterprise',
        name: 'Enterprise',
        includes: `
          <li>SAML single sign-on</li>
          <li>Access provisioning</li>
          <li>24/7 support</li>
        `,
        currency: '',
        priceMonthly: 'Custom'
      });

      this.isLoading = false;
    });
  }
}
