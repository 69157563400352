import { Component, Input } from '@angular/core';

@Component({
  selector: '[iconComment]',
  styles: [
    ''
  ],
  template: `
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 9" width="15" height="9">
      <rect width="15" height="1.28571" fill="#fff"/>
      <rect y="3.85718" width="15" height="1.28571" fill="#fff"/>
      <rect x="2.5" y="7.71423" width="10" height="1.28571" fill="#fff"/>
    </svg>
  `,
})
export class IconComment {
}
