import { Component, ViewEncapsulation } from '@angular/core';
import { AnalyticsService } from '../../services/analytics';

@Component({
  selector: 'app-root',
  templateUrl: './root.html',
  styleUrls: ['./root.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RootPage {

  constructor(
    public analyticsService: AnalyticsService
  ) {}
}
