import { Component } from '@angular/core';
import { DownloadService } from '../../services/download';
import { combineLatest } from 'rxjs';
import { AnalyticsService } from '../../services/analytics';
import { TrackPage } from '../trackPage';
import { InternalPages, ActionDownloadedApp, ActionLocations } from '../../model';

@Component({
  selector: 'app-download-page',
  templateUrl: './download.html',
  styleUrls: ['./download.scss']
})
export class DownloadPage extends TrackPage {
  public macInfo: { version: string, file: string, releaseNotes: string };
  public winInfo: { version: string, file: string, releaseNotes: string };
  public isLoading = true;
  public downloadUrlMac: string;
  public downloadUrlWin: string;

  constructor(
    private downloadService: DownloadService,
    protected analyticsService: AnalyticsService
  ) {
    super(analyticsService, InternalPages.download);
    combineLatest(
      this.downloadService.loadLastest('Mac'),
      this.downloadService.loadLastest('Windows')
    ).subscribe(([macInfo, winInfo]) => {
      this.macInfo = macInfo;
      this.winInfo = winInfo;
      this.downloadUrlMac = `${this.downloadService.cdnBaseHref}/${this.macInfo.file}`;
      this.downloadUrlWin = `${this.downloadService.cdnBaseHref}/${this.winInfo.file}`;
      this.isLoading = false;
    });
  }

  download(platform: 'Mac' | 'Windows') {
    let version;
    let file;
    if (platform === 'Mac') {
      version = this.macInfo.version;
      file = this.macInfo.file;
    } else if (platform === 'Windows') {
      version = this.winInfo.version;
      file = this.winInfo.file;
    }

    const action: ActionDownloadedApp = {
      type: 'downloadedApp',
      actionLocation: ActionLocations.body,
      fromPage: InternalPages.download,
      platform,
      version
    };
    this.analyticsService.track(action);
  }

}
