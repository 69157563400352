import { Component } from '@angular/core';
import { AnalyticsService } from '../../services/analytics';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.html',
  styleUrls: ['./footer.scss']
})
export class FooterComponent {

  public year = new Date().getFullYear();

  constructor(
    public analyticsService: AnalyticsService
  ) {}

}
