import { Component } from '@angular/core';

@Component({
  selector: 'app-features',
  templateUrl: './features.html',
  styleUrls: ['./features.scss']
})
export class FeaturesComponent {
  public otherFeaturesImg = 'darkmode';
  public otherFeaturesTitle = 'Dark mode';

}
