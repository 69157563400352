import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { RootPage } from './containers/root/root';
import { MainPage } from './containers/main/main';
import { PricingPage } from './containers/pricing/pricing';
import { DownloadPage } from './containers/download/download';
import { PrivacyPage } from './containers/privacy/privacy';
import { TermsPage } from './containers/terms/terms';
import { NotFoundPage } from './containers/not-found/not-found';

import { DownloadService } from './services/download';
import { AnalyticsService } from './services/analytics';

import { LogoComponent } from './components/logo/logo';
import { WelcomeComponent } from './components/welcome/welcome';
import { FeaturesComponent } from './components/features/features';
import { DownloadComponent } from './components/download/download';
import { BlogComponent } from './components/blog/blog';
import { PressComponent } from './components/press/press';
import { FooterComponent } from './components/footer/footer';
import { VideoPlayerComponent } from './components/video-player/video-player';
import { IconComment, IconMindmap, IconBoy, IconGirl } from './components/icons';
import { routes } from './routes';

import { environment } from '../environments/environment';
import * as Sentry from '@sentry/browser';

export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    Sentry.init({
      dsn: 'https://26f55ac1e61b4e3fa398028931856b4b@sentry.io/1458382',
    });
  }

  handleError(err: any): void {
    Sentry.captureException(err.originalError || err);
    throw err;
  }
}

export function provideErrorHandler() {
  if (environment.production) {
    return new SentryErrorHandler();
  } else {
    return new ErrorHandler();
  }
}

@NgModule({
  declarations: [
    RootPage,
    MainPage,
    PricingPage,
    DownloadPage,
    PrivacyPage,
    TermsPage,
    NotFoundPage,
    LogoComponent,
    WelcomeComponent,
    FeaturesComponent,
    DownloadComponent,
    BlogComponent,
    PressComponent,
    FooterComponent,
    VideoPlayerComponent,
    IconComment,
    IconMindmap,
    IconBoy,
    IconGirl
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DeviceDetectorModule.forRoot(),
    RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' }),
  ],
  providers: [
    { provide: ErrorHandler, useFactory: provideErrorHandler },
    DownloadService,
    AnalyticsService
  ],
  bootstrap: [RootPage]
})
export class AppModule { }
