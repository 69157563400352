import { Component } from '@angular/core';
import { TrackPage } from '../trackPage';
import { AnalyticsService } from '../../services/analytics';
import { InternalPages } from '../../model';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.html',
  styleUrls: ['./not-found.scss']
})
export class NotFoundPage extends TrackPage {

  constructor(
    protected analyticsService: AnalyticsService
  ) {
    super(analyticsService, InternalPages.notFound);
  }

}
