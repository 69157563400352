import { Routes } from '@angular/router';

import { MainPage } from './containers/main/main';
import { PricingPage } from './containers/pricing/pricing';
import { DownloadPage } from './containers/download/download';
import { PrivacyPage } from './containers/privacy/privacy';
import { TermsPage } from './containers/terms/terms';
import { NotFoundPage } from './containers/not-found/not-found';

export const routes: Routes = [{
  path: '',
  component: MainPage,
}, {
  path: 'pricing',
  component: PricingPage,
  pathMatch: 'full'
}, {
  path: 'download',
  component: DownloadPage,
  pathMatch: 'full'
}, {
  path: 'privacy',
  component: PrivacyPage,
  pathMatch: 'full'
}, {
  path: 'terms',
  component: TermsPage,
  pathMatch: 'full'
}, {
  path: '**',
  component: NotFoundPage,
  pathMatch: 'full'
}];
