import { Component } from '@angular/core';
import { TrackPage } from '../trackPage';
import { AnalyticsService } from '../../services/analytics';
import { InternalPages } from '../../model';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.html',
  styleUrls: ['../privacy/privacy.scss']
})
export class TermsPage extends TrackPage {

  constructor(
    protected analyticsService: AnalyticsService
  ) {
    super(analyticsService, InternalPages.terms);
  }

}
