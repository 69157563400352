import { Injectable } from '@angular/core';
import {
  AnalyticsAction,
  ActionClickedPage,
  ActionClickedExternalPage,
  ActionLocations,
  InternalPages,
  ExternalPages,
  ExternalPageParams,
  ActionLoadedPage
} from '../model';

@Injectable()
export class AnalyticsService {
  public internalPages = InternalPages;
  public externalPages = ExternalPages;
  public actionLocations = ActionLocations;

  track({ type, ...others }: AnalyticsAction) {
    const finalType = `web_${type}`;
    console.log(`%c[Action] ${finalType}`, 'color: #FF1A85', others);
    analytics.track(finalType, others);
  }

  protected isInternalPage = (page: any): page is InternalPages => (page in this.internalPages);
  protected isExternalPage = (page: any): page is ExternalPages => (page in this.externalPages);

  trackClick(
    { actionLocation = ActionLocations.menu, toPage, fromPage, params }:
    { actionLocation?: ActionLocations, toPage: InternalPages | ExternalPages, fromPage?: InternalPages, params?: ExternalPageParams }
  ) {
    let action: ActionClickedPage | ActionClickedExternalPage;
    if (this.isInternalPage(toPage)) {
      action = {
        type: 'clickedPage',
        actionLocation,
        toPage
      } as ActionClickedPage;
    } else {
      action = {
        type: 'clickedExternalPage',
        actionLocation,
        toPage,
        ...params
      } as ActionClickedExternalPage;
      if (fromPage) {
        action.fromPage = fromPage;
      }
    }
    this.track(action);
  }

  trackPageLoaded(
    { toPage }: { toPage: InternalPages }
  ) {
    const action: ActionLoadedPage = {
      type: 'loadedPage',
      toPage
    };
    this.track(action);
  }
}
