import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.html',
  styleUrls: ['./video-player.scss']
})
export class VideoPlayerComponent {
  @Input() src: string;
  @Input() poster: string;
  @Input() overlay = true;
  @Input() title: string;
}
