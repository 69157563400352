import { IconComment } from './comment';
import { IconMindmap } from './mindmap';
import { IconBoy } from './boy';
import { IconGirl } from './girl';

export {
  IconComment,
  IconMindmap,
  IconBoy,
  IconGirl
};
